html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  white-space: nowrap;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  height: 100%;
  vertical-align: top;
  white-space: nowrap;
  display: inline-block;
}

.c-scrollbar {
  height: 100%;
  opacity: 0;
  transform-origin: 100%;
  width: 11px;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-dragging .c-scrollbar, .has-scroll-scrolling .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  height: 10px;
  width: 100%;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  cursor: -webkit-grab;
  cursor: grab;
  opacity: .5;
  width: 7px;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

@font-face {
  font-family: type;
  src: url("Hellix-SemiBold.cc18967d.woff2");
}

html.has-scroll-smooth {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

body {
  background: #9ea29f;
  margin: 0;
  padding: 0;
  font-family: type;
}

.header {
  height: 100vh;
  width: 100vw;
  background: #2c3639;
  flex-direction: column;
  justify-content: center;
  padding: 0 5%;
  display: flex;
}

.title-main {
  max-width: 500px;
  flex-direction: column;
  display: flex;
}

#site {
  position: relative;
  top: 0;
  left: 0;
}

@keyframes headline {
  0% {
    font-size: .3rem;
  }

  100% {
    font-size: 10rem;
  }
}

.tagline {
  color: #fff;
  opacity: .8;
  max-width: 360px;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  margin-top: 40px;
  font-family: Outfit, sans-serif;
  font-size: 29px;
  font-weight: 300;
  display: inline-flex;
}

.tagline p {
  margin: 0;
  font-size: 29px;
}

.nav {
  color: #fff;
  z-index: 2222;
  width: calc(100vw - 10%);
  height: 120px;
  letter-spacing: .4px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 5%;
  display: flex;
  position: fixed;
  top: 0;
}

.nav h2 {
  margin: 0;
  padding: 0;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.nav .menu {
  gap: 20px;
  margin: 0;
  padding: 0;
  font-family: Outfit, sans-serif;
  font-weight: 400;
  list-style: none;
  display: flex;
}

.span {
  color: #f0f8ff;
  font-size: 18px;
}

.headline {
  text-transform: uppercase;
  color: #fff;
  white-space: nowrap;
  margin: auto;
  font-size: max(14px, 8vw);
  line-height: 1;
  left: 0;
  right: 0;
}

svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

text {
  fill: #fff;
  text-anchor: middle;
  pointer-events: auto;
}

.bat {
  opacity: 0;
  color: #000;
  background-color: #0000;
  padding: 0;
  transition: all .9s ease-in-out;
}

.battery {
  opacity: 1;
  color: #fff;
  background-color: #000;
  padding: 30px;
}

#loader {
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.1170ae7e.css.map */
